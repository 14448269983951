var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "proview-min",
      style: "width: " + _vm.width + "; height: " + _vm.height,
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "screen-view" }, [_vm._t("content")], 2),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "receiver" }, [
      _c("div", { staticClass: "long-view" }),
      _c("div", { staticClass: "short-view" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }